<template>
  <div v-loading.fullscreen.lock="logging" element-loading-text="登陆中……" class="contaniner">
    <div style="width: 400px">
      <div class="drag-div" />
      <div class="header">
        <img
          class="logo"
          src="~@/assets/logo.png"
        />
      </div>
      <div class="main-body">
        <div class="input-box">
          <el-input ref="userNameInput" v-model="account" prefix-icon="el-icon-user" placeholder="用户名">
          </el-input>
        </div>
        <div class="input-box">
          <el-input type="password" v-model="password" prefix-icon="el-icon-lock" placeholder="密码">
          </el-input>
        </div>
        <div class="input-box" style="justify-content: left">
          <el-checkbox v-model="remember" label="记住密码"></el-checkbox>
        </div>
        <div class="submit-row">
          <el-button type="primary" block :disabled="!account || !password"
            @click="(account && password) ? login() : ''">登录
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
import {
  mapState
} from 'vuex'
import {
  YeIMUniSDK
} from '../../utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';
import { ipcApiRoute, requestHttp } from "@/api/main";

export default {
  data() {
    return {
      logo: "~@/assets/logo.png",
      accountInputActive: false,
      passwordInputActive: false,
      showPlainText: false,
      remember: true,
      checked: false,
      logging: false,
      account: '',
      userId: '',
      password: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user
    })
  },
  created() {
    // const that = this
    // let user = localStorage.getItem('user');
    // if (user) {
    //   this.logging = true
    //   this.$store.dispatch('yeim/reset')
    //   setTimeout(function () {
    //     that.logging = false
    //   }, 20000)
    // }

    if (localStorage.getItem('remember_username')) {
      this.account = localStorage.getItem('remember_username')
      this.password = localStorage.getItem('remember_password')
    }

    if (!this.$store.state.yeim.yeimLogin) {
      this.$store.dispatch('yeim/reset')
    }
  },
  methods: {
    register() {
      // uni.navigateTo({
      //   url: './register'
      // })
    },
    clearAccount() {

    },
    clearPassword() {

    },
    checkChanged() {

    },
    login() {
      if (!this.account) {
        return this.$message.info('请输入用户名');
      }
      if (!this.password) {
        return this.$message.info('请输入密码');
      }
      this.logging = true
      //模拟计算sign，换取登陆Token，此处建议在各个系统的服务端进行。
      let timestamp = (new Date()).getTime() + 86400 * 1000; //1天后过期
      let sign = md5(this.account + timestamp + "50abd47112ebe8c5a73f4694c96a49ce");

      this.$store.state.yeim.conversationList = []
      this.$store.state.yeim.groupList = []
      this.$store.state.yeim.friendList = []
      this.$store.state.yeim.friendApplyList = []
      this.$store.state.yeim.friendApplyUnread = 0

      requestHttp(YeIMUniSDK.getInstance().defaults.baseURL + "/user/token/get", {
        userId: this.account,
        password: this.password,
        isAgent: 1,
        from: 'pc',
        timestamp: timestamp,
        sign: sign
      }).then(async (response) => {
        this.logging = false
        if (response.status == 400) {
          this.$message.info(response.msg)
        } else {
          if (this.remember) {
            localStorage.setItem('remember_username', this.account)
            localStorage.setItem('remember_password', this.password)
          }
          // 换取token成功，使用token进行登陆
          await this.$store.dispatch('yeim/login', {
            userId: this.account,
            token: response.data.token,
            timestamp: timestamp
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contaniner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh);
  background: url(~@/assets/image/login_bg.png) no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .drag-div {
    height: 65px;
    width: 100%;
    -webkit-app-region: drag
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      margin-bottom: 10px;
    }
  }

  .main-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 30px 0px;
    border-radius: 8px;

    .input-box {
      width: 80%;
      margin-top: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      padding: 11px 0;
      border-radius: 5px;

      .left-icon {
        cursor: pointer;
        position: absolute;
        left: 15%;
      }

      .right-icon {
        cursor: pointer;
        position: absolute;
        right: 15%;
      }

      .uni-input {
        width: 60%;
      }

      :deep(.input-placeholder) {
        span-align: center;
        font-size: 15px;
        font-weight: normal;
      }

      :deep(.uni-input-input) {
        span-align: center;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .active {
      background-color: white;
    }

    .agreement-row {
      margin-top: 15px;

      .uni-list-cell {
        display: flex;
        align-items: center;

        uni-checkbox {
          display: flex;
        }

        :deep(.uni-checkbox-input) {
          width: 14px;
          height: 14px;
          border-radius: 14px;
          border-color: transparent;
        }

        .checked {
          :deep(.uni-checkbox-input) {
            width: 14px;
            height: 14px;
            border-radius: 14px;
            background-color: #0499ff;
            border-color: transparent;

            svg {
              width: 14px;
              height: 14px;

              path {
                fill: white;
              }
            }
          }
        }

        :deep(.uni-checkbox-input-checked::before) {
          font-size: 15px;
          color: white;
        }

        .describe {
          font-size: 12px;
          color: #999999;

          span {
            color: #2e77e5;
          }
        }
      }
    }

    .submit-row {
      margin-top: 22px;
      width: 60%;

      .submit-button {
        width: 80%;
        background-color: rgba(43, 161, 255, 0.3);
        font-size: 15px;
      }

      .active {
        background-color: rgb(43, 161, 255);
      }

      .submit-button::after {
        border: unset;
      }
    }
  }

  .footer {
    margin: auto;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    .span {
      cursor: pointer;
      color: #2e77e5;
    }

    .separator {
      color: #dddddd;
      width: 23px;
      font-size: 12px;
      span-align: center;
    }
  }
}
</style>
